import React, { useEffect, useRef } from 'react';
import { Modal } from 'Modal';
import { getOpenSourceLinkUrl } from '../../../utils/meeting-url';
import { connect } from 'react-redux';
import './index.scss';
import { ABOUT_VERSION, ABOUT_CONTENT, ABOUT_OPEN_SOURCE } from '../../../resource';
import { isTeslaMode } from '../../../utils';
import { useAppDispatch } from '../../../store/store-hooks';
import { setModal } from '../../../store/modal/modal-store';
import { IconCloseFill } from '@zoom/icons-react';

const About = () => {
    const dispatch = useAppDispatch();
    const closeRef = useRef(null);
    useEffect(() => {
        closeRef.current && closeRef.current.focus();
    }, []);

    const closeMe = () => {
        dispatch(setModal({ name: 'about', data: null }));
    };

    const openSourceUrl = getOpenSourceLinkUrl();

    return (
        <Modal
            isOpen={true}
            needBackdrop={!isTeslaMode()}
            isDraggable={false}
            aria={{ labelledby: 'zm-about-version' }}
            centered={true}
            onRequestClose={closeMe}
        >
            <div className="modal-about">
                <button ref={closeRef} className="about-close" onClick={closeMe}>
                    <IconCloseFill className="about-close-icon" />
                </button>
                <i className="zoom-trademark about-zoom-logo"></i>
                <p className="about-version" id="zm-about-version">
                    {ABOUT_VERSION} {process.env.BUILD_VERSION} ({process.env.BUILD_DATE})
                </p>
                <p id="zm-about-copyright">{ABOUT_CONTENT(`2012-${window.PwaConfig.copyrightYear || 2024}`)}</p>
                {isTeslaMode() ? null : (
                    <a className="about__open-source" href={openSourceUrl} target="_blank" rel="noopener noreferrer">
                        <span className="about__open-source-text">{ABOUT_OPEN_SOURCE}</span>
                        <i className="about__open-source-link-icon"></i>
                    </a>
                )}
            </div>
        </Modal>
    );
};

export default connect()(About);
